/**
 * Use this query param to control whether the #/account route should attempt to
 * re-establish the latest route the user has visited.
 *
 * E.g. we redirect to `#/account?shouldAttemptToReestablishLastVisitedRoute=true
 * after logging in to trigger this.
 *
 * Hint: See 'useRedirectToStartingPoint' in 'app/react/routes/account/account.tsx'
 * to see how the latest route is re-established.
 */
export const SHOULD_ATTEMPT_TO_REESTABLISH_LAST_VISITED_ROUTE_QUERY_PARAM =
    'shouldAttemptToReestablishLastVisitedRoute';

export const ACCOUNT_SUBROUTE_CONTENT_ID = 'accountSubrouteContentId';
export const PAY_NOW_MODAL_TOOLBOX = 'payNowModalToolbox';
/**
 * This query parameter is used for a single use case: Showing a parent onboarding flow once a parent first joins.
 * We're using a query parameter for this to be able to easily **only** display this flow once a parent
 * has successfully created their password after receiving an email notification to join Famly.
 * More details here: https://www.notion.so/famly/Parent-Onboarding-dce8ed8b46ec46379adf5b4aaae4e3ea?pvs=4.
 */
export const JUST_SIGNED_UP_PARAM = 'justSignedUp';
export const LOGIN_VALIDATION_CARD_WIDTH = 453;
export const REFERRER_PARAM = 'referrer';

/**
 * This parameter is used to allow the backend to send redirect errors.
 *
 * This can be useful when authenticating against external APIs such as the
 * HMRC API, that is used in conjunction with the "tax free childcare"
 *
 * @see {@link https://famlyapp.atlassian.net/browse/FAB-3165}
 */
export const REDIRECT_ERROR_PARAM = 'redirectError';
